import { mediabankApi as api, mediabankHeaders } from '../../mediabank';

const collectionsHeaders = context => ({ ...mediabankHeaders(), 'x-content': context });

const getCollections = async ({ context = 'view' } = {}) =>
    await api.get('/collections', {
        headers: collectionsHeaders(context),
    });

const getCollection = async ({ id, queryParams = '', context = 'view' }) =>
    await api.get(`/collections/${id}${queryParams}`, {
        headers: collectionsHeaders(context),
    });

const createCollection = async ({ attributes }) =>
    await api.post('/collections', {
        headers: mediabankHeaders(),
        data: {
            data: {
                type: 'collections',
                attributes,
            },
        },
    });

const updateCollection = async ({ id, attributes }) =>
    await api.patch(`/collections/${id}`, {
        headers: mediabankHeaders(),
        data: {
            data: {
                type: 'collections',
                id,
                attributes,
            },
        },
    });

const deleteCollection = async ({ id }) =>
    await api.delete(`/collections/${id}`, {
        headers: mediabankHeaders(),
    });

const copyCollection = async ({ id }) =>
    await api.post(`/collections/${id}/copy`, {
        headers: mediabankHeaders(),
    });

const shareCollection = async ({ id, companies }) =>
    await api.post(`/collections/${id}/share`, {
        headers: mediabankHeaders(),
        data: {
            data: {
                type: 'collections',
                attributes: companies,
            },
        },
    });

const getSubcollection = async ({ collectionId, subcollectionId, queryParams = '', context = 'view' }) =>
    await api.get(`/collections/${collectionId}/subcollections/${subcollectionId}${queryParams}`, {
        headers: collectionsHeaders(context),
    });

const createSubcollection = async ({ collectionId, attributes }) =>
    await api.post(`/collections/${collectionId}/subcollections`, {
        headers: mediabankHeaders(),
        data: {
            data: {
                type: 'subcollections',
                attributes,
            },
        },
    });

const updateSubcollection = async ({ collectionId, subcollectionId, attributes }) =>
    await api.patch(`/collections/${collectionId}/subcollections/${subcollectionId}`, {
        headers: mediabankHeaders(),
        data: {
            data: {
                type: 'subcollections',
                id: subcollectionId,
                attributes,
            },
        },
    });

const deleteSubcollection = async ({ collectionId, subcollectionId }) =>
    await api.delete(`/collections/${collectionId}/subcollections/${subcollectionId}`, {
        headers: mediabankHeaders(),
    });

const copySubcollection = async ({ collectionId, subcollectionId }) =>
    await api.post(`/collections/${collectionId}/subcollections/${subcollectionId}`, {
        headers: mediabankHeaders(),
    });

export default {
    getCollections,
    getCollection,
    createCollection,
    updateCollection,
    deleteCollection,
    copyCollection,
    shareCollection,
    getSubcollection,
    createSubcollection,
    updateSubcollection,
    deleteSubcollection,
    copySubcollection,
};
