import React, { useContext } from 'react';
import cx from 'clsx';
import { Box, Button } from '@material-ui/core';
import { Form, FormTextField, CircleLoading, NotificationsContext } from '@mediabank/uikit';
import { useLoginFormStyles, useInputStyles } from './styles';
import { setToken, tokenService } from '@mediabank/client';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import AuthService from '../../services/AuthService';
import { errorPayload } from '../../utils/error';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { Token } from '../../lib/Token';

const LoginForm = () => {
    const classes = useLoginFormStyles();
    const inputClasses = useInputStyles();

    let history = useHistory();
    const queryClient = useQueryClient();

    const { addToastError } = useContext(NotificationsContext);

    const login = useMutation(
        (payload: unknown) => tokenService.authWithCred(payload as { email: string; password: string }),
        {
            onSuccess: data => {
                const token = data.data.token;
                localStorage.setItem('mediabankToken', token);
                setToken(token);
                AuthService.scheduleJwtRefresh();
                queryClient.setDefaultOptions({
                    queries: {
                        cacheTime: Infinity,
                    },
                });
                queryClient.setQueryData('userProfile', jwtDecode<Token>(token).user);
                queryClient.setDefaultOptions({});
                history.replace('/');
            },
            onError: error => {
                if (axios.isAxiosError(error)) {
                    const errorResponse = errorPayload(error);
                    addToastError({
                        cy: 'unauthorizedError',
                        title: 'Auth fail',
                        subtitle: `${errorResponse.code ? errorResponse.code + ': ' : ''}${errorResponse.message}`,
                        caption:
                            errorResponse.code === 401
                                ? 'Incorrect email/password'
                                : errorResponse.feedback && errorResponse.feedback,
                    });
                }
            },
        }
    );

    return (
        <div className={cx(classes.root, classes.loginForm)}>
            <Box width={0.5} mb={2} />
            <Box width={1} clone>
                <Form onSubmit={login.mutate} className={classes.form}>
                    <FormTextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="email"
                        autoComplete="username"
                        data-cy="username"
                        InputProps={{ classes: inputClasses }}
                    />
                    <FormTextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        data-cy="password"
                        InputProps={{ classes: inputClasses }}
                    />

                    <Button type="submit" fullWidth variant="contained" className={classes.submit} data-cy="submit">
                        {login.isLoading ? <CircleLoading height={12} size={16} /> : 'Log in'}
                    </Button>
                </Form>
            </Box>
        </div>
    );
};

export default LoginForm;
