import { HLS_EXTENSIONS, IOS, IS_SAFARI, DASH_EXTENSIONS, IS_IMAGE, EMAIL } from './regex.js';

export const decodeToken = tokenStr => {
    try {
        const base64Url = tokenStr.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

        return JSON.parse(window.atob(base64));
    } catch (e) {
        console.error(e);

        return {};
    }
};

export const shouldUseDASH = url => DASH_EXTENSIONS.test(url);

export const shouldUseHLS = url => HLS_EXTENSIONS.test(url) && !IOS;

export const isAudioAsset = mimeType => mimeType && mimeType.includes('audio');

export const isImage = url => IS_IMAGE.test(url);

export const isSafari = () => IS_SAFARI;

export const isNotOnlyWhitespace = str => /\S/.test(str);

export const isMediaStream = url =>
    typeof window !== 'undefined' && typeof window.MediaStream !== 'undefined' && url instanceof window.MediaStream;

export const getSource = url => {
    const useHLS = shouldUseHLS(url);
    const useDASH = shouldUseDASH(url);
    if (url instanceof Array || isMediaStream(url) || useHLS || useDASH) {
        return undefined;
    }

    return url;
};

export const filterDataSet = props =>
    Object.keys(props).reduce((acc, key) => {
        if (key.startsWith('data-')) {
            return { ...acc, dataSet: { ...acc.dataSet, [key]: props[key] } };
        } else {
            return { ...acc, nonDataSetProps: { ...acc.nonDataSetProps, [key]: props[key] } };
        }
    }, {});

export const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const padS = (num, size) => ('000' + num).slice(size * -1);

export const formatTime = (second, showMilliseconds = false) => {
    const time = parseFloat(second).toFixed(3);
    const hours = Math.floor(time / 60 / 60);
    const minutes = Math.floor(time / 60) % 60;
    const seconds = Math.floor(time - minutes * 60);
    const milliseconds = time.slice(-3);

    return (
        `${padS(hours, 2)}:${padS(minutes, 2)}:${padS(seconds, 2)}` +
        (showMilliseconds ? `:${padS(milliseconds, 3)}` : '')
    );
};

export const convertMillisToDuration = ms => {
    if (!ms || ms <= 0) {
        return '-';
    }
    let seconds = Math.floor(ms / 1000);
    let minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    let hours = Math.floor(minutes / 60);

    minutes = minutes % 60;
    hours = hours % 24;

    return (
        (hours < 10 ? '0' : '') +
        hours +
        ':' +
        (minutes < 10 ? '0' : '') +
        minutes +
        ':' +
        (seconds < 10 ? '0' : '') +
        seconds
    );
};

export const getUTCStringDate = date => {
    if (!date) {
        return '';
    }
    const dateWithoutGMT = date.toString()?.split('GMT')[0];
    const postText = date.getUTCHours() <= 12 ? 'AM' : 'PM';

    return dateWithoutGMT.concat(` ${postText}`);
};

const pad = val => (val < 10 ? '0' + val : val);

export const formatTimeByFrame = (seconds, fps = 24, use24hourClock = false) => {
    const hours = Math.floor(seconds / 3600);

    return [
        pad(use24hourClock ? hours % 24 : hours),
        pad(Math.floor((seconds % 3600) / 60)),
        pad(Math.floor(seconds % 60)),
        pad(Math.floor((seconds * fps) % fps)),
    ].join(':');
};

// EXPORTING ONLY FOR TEST PURPOSE
export const assembleHistory = ({ locationSearch, editKey, newValue }) =>
    locationSearch.includes(editKey)
        ? locationSearch
              .substring(1) // Remove '?' from url search string
              .split('&')
              .reduce((acc, curr, index) => {
                  const [key, value] = curr.split('=');
                  if (!key || !value) {
                      return acc;
                  }
                  const prefix = index > 0 && index < acc.length ? '&' : '';
                  if (key === editKey) {
                      return acc + `${prefix}${key}=${newValue}`;
                  }
                  return acc + `${prefix}${key}=${value}`;
              }, '?')
        : locationSearch.length > 0
        ? `${locationSearch}&${editKey}=${newValue}`
        : `?${editKey}=${newValue}`;

export const updateURLParams = (history, url) => ({ key, newValue, pos }) => {
    let newHistory = assembleHistory({
        locationSearch: history.location.search,
        editKey: key,
        newValue,
    });
    if (pos) {
        newHistory = assembleHistory({ locationSearch: newHistory, editKey: 'p', newValue: pos * 1000 });
    } else {
        const queryParams = new URLSearchParams(newHistory);

        if (queryParams.has('p')) {
            queryParams.delete('p');
            newHistory = `?${queryParams.toString()}`;
        }
    }

    history.push(`${url}${newHistory}`);
};

export const updateUrlQueryString = params => {
    const urlParams = new URLSearchParams(window.location.search);
    params.forEach(param => {
        urlParams.set(param.key, param.value);
    });

    window.history.pushState(null, '', `${window.location.pathname}?${urlParams.toString()}`);
};

export const updateUrlQueryWithSearchParams = params => {
    const urlParams = [];
    Object.keys(params).forEach(key => {
        if (params[key]) {
            urlParams.push({ key, value: params[key] });
        }
    });
    updateUrlQueryString(urlParams);
};

export const getPosterImageUrl = (imgSrc, percentage, posterCount) => {
    let result = imgSrc;
    if (percentage >= 0.05 && posterCount !== undefined) {
        const ab = Math.max(Math.round(percentage * posterCount), 1);
        result = imgSrc.replace(/poster.*.(png|jpg)/, `poster_${ab}.jpg`);
    }

    return result;
};

export const isEmpty = value =>
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0);

export const safeParseJSON = (json, defaultValue) => {
    if (!json) {
        return defaultValue;
    }

    try {
        return JSON.parse(json);
    } catch (_) {
        return defaultValue;
    }
};

export const isGroupMode = permissionType => permissionType === 'group';

/**
 * Accepts anystring@anystring.anystring where anystring can include any character except space and @
 */
export const isValidEmail = email => EMAIL.test(email);

export * from './date';
export * from './cypress/cypress';
export * from './sentry/sentry';
export * from './regex';
export * from './uuid';
export * from './input';
export * from './asset';
