import React, { Suspense, lazy } from 'react';
import { ApplicationLoading } from '@mediabank/uikit';
import { Switch, Redirect, Route } from 'react-router-dom';
import ErrorPage from '../pages/ErrorPage';

const CollectionForm = lazy(() => import('../components/CollectionForm'));
const CollectionsPage = lazy(() => import('../pages/CollectionsPage'));

// remember to update `rewrites` in vercel.json as routes are changed
const ProtectedRoutes = () => (
    <Suspense fallback={<ApplicationLoading />}>
        <Switch>
            <Route exact path="/">
                <Redirect to="/collections" />
            </Route>
            <Route exact path="/collections" component={CollectionsPage} key={'/collections'} />,
            <Route exact path="/collections/new" component={() => <CollectionForm />} key={'/collections/new'} />
            ,
            <Route
                exact
                path="/collections/:collectionid"
                component={() => <CollectionForm />}
                key={'/collections/:collectionid'}
            />
            ,
            <Route
                exact
                path="/collections/:collectionid/subcollections/new"
                component={() => <CollectionForm />}
                key={'/collections/:collectionid/subcollections/new'}
            />
            ,
            <Route
                exact
                path="/collections/:collectionid/subcollections/:subcollectionid"
                component={() => <CollectionForm />}
                key={'/collections/:collectionid/subcollections/:subcollectionid'}
            />
            ,
            <Route path="*" component={ErrorPage} />
        </Switch>
    </Suspense>
);

export default ProtectedRoutes;
