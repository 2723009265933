import { setToken, tokenService } from '@mediabank/client';
import jwtDecode from 'jwt-decode';
import { Token } from '../lib/Token';

async function localStorageAuth() {
    const mediabankToken = localStorage.getItem('mediabankToken');
    return validateToken(mediabankToken);
}

async function cookieAuth() {
    const authCookie = document.cookie.split('; ').find(row => row.startsWith('auth='));
    const mediabankToken = authCookie ? authCookie.split('=')[1] : '';
    return validateToken(mediabankToken);
}

const validateToken = async (token: string | null) => {
    if (token) {
        return await tokenService
            .validate({
                token: token,
            })
            .then(() => {
                localStorage.setItem('mediabankToken', token);
                setToken(token);
                scheduleJwtRefresh();
                return jwtDecode<Token>(token).user;
            })
            .catch(error => {
                localStorage.removeItem('mediabankToken');
                return Promise.reject(error);
            });
    }
};

const scheduleJwtRefresh = () => {
    const jwt = localStorage.getItem('mediabankToken');
    if (jwt) {
        setTimeout(() => {
            refreshMediabankToken(jwt).then(() => scheduleJwtRefresh());
        }, getJwtTtl(jwt));
    }
};

const refreshMediabankToken = async (jwt: string) => {
    const {
        data: { token },
    } = await tokenService.refresh({ token: jwt });
    localStorage.setItem('mediabankToken', token);
    setToken(token);
};

const getJwtTtl = (jwt: string) => {
    const decoded = jwtDecode<Token>(jwt);
    const nowSeconds = Date.now() / 1000;
    const expSeconds = decoded.exp;
    const bufferSeconds = 60;
    return Math.round((expSeconds - nowSeconds - bufferSeconds) * 1000);
};

export default {
    localStorageAuth,
    cookieAuth,
    scheduleJwtRefresh,
};
