import { mediabankApi as api, mediabankHeaders } from '../../mediabank';

export const getRelatedAssets = async id => {
    const response = await api.get(`/assets/${id}/related`, {
        headers: mediabankHeaders(),
    });
    return response;
};

const getAssetMenus = assetGroupData =>
    assetGroupData
        .filter(item => item.type === 'relatedAssetsMenus')
        .map(item => ({
            id: item.id,
            caption: item.attributes.label,
            subMenuItems: item.relationships.menuItems.data.map(menuItem => {
                const currentItem = assetGroupData.find(assetGroupDataItem => assetGroupDataItem.id === menuItem.id);
                const { alternateAssets, ...restRelationships } = currentItem.relationships;

                return {
                    id: currentItem.id,
                    caption: currentItem.attributes.label,
                    relationships: restRelationships,
                    alternateAssets:
                        alternateAssets &&
                        alternateAssets.data &&
                        alternateAssets.data.map(alternateAsset => ({
                            id: `${alternateAsset.id}`,
                            caption: alternateAsset.meta.label,
                        })),
                };
            }),
        }));

const getAssetRootMenus = (assetGroupData, assetMenus) =>
    assetGroupData
        .filter(item => item.type === 'relatedAssetsMenus' && item.attributes.isTopmenu)
        .map(item => {
            const currentItem = assetMenus.find(assetMenu => assetMenu.id === item.id);
            const subMenuItems = currentItem.subMenuItems.map(subMenuItem =>
                subMenuItem.relationships.menuItems
                    ? assetMenus.find(assetMenu => assetMenu.id === subMenuItem.id)
                    : subMenuItem
            );
            return {
                ...currentItem,
                subMenuItems,
            };
        });

// generate the data structure for menu component to digest
const processData = assetGroupData => {
    const assetMenus = getAssetMenus(assetGroupData);
    const rootMenu = getAssetRootMenus(assetGroupData, assetMenus);

    return rootMenu;
};

const findPathById = (list, id, result) => {
    if (typeof id !== 'string' || id === '') {
        return false;
    }

    for (let item of list) {
        if (item.id === id) {
            return [item];
        } else if (item.subMenuItems || item.alternateAssets) {
            const options = item.subMenuItems || item.alternateAssets;
            const findInChild = findPathById(options, id, result);
            if (findInChild) {
                return item.subMenuItems ? [item, ...findInChild] : [item];
            }
        }
    }

    return false;
};

export default getRelatedAssets;
export { processData, findPathById, getAssetMenus, getAssetRootMenus };
