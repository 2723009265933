import React from 'react';
import { mbTheme, useGlobalStyles, DarkTheme } from '@mediabank/theme';
import { ThemeProvider, createMuiTheme, CssBaseline, makeStyles } from '@material-ui/core';
import AppRoutes from './routes/AppRoutes';
import AuthService from './services/AuthService';
import { AuthProps } from './lib/Auth';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { NotificationsProvider } from '@mediabank/uikit';
import { ReactQueryDevtools } from 'react-query/devtools';

declare module '@material-ui/core/styles/createMuiTheme' {
    interface Theme extends DarkTheme {}
    interface ThemeOptions extends DarkTheme {}
}

export const theme = createMuiTheme(mbTheme[0]);

const useStyles = makeStyles(() => ({
    notificationContainer: { position: 'fixed', bottom: '5%' },
}));

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: process.env.NODE_ENV === 'production',
        },
    },
});

const App = ({ auth }: AuthProps) => {
    const classes = useStyles();
    useGlobalStyles(theme)();

    return (
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <NotificationsProvider customClasses={{ containerRoot: classes.notificationContainer }}>
                        <BrowserRouter basename={auth ? 'apps/localadmin' : ''}>
                            <AppRoutes auth={auth ?? AuthService.localStorageAuth} />
                        </BrowserRouter>
                    </NotificationsProvider>
                </ThemeProvider>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </React.StrictMode>
    );
};

export default App;
