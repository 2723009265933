import { makeStyles } from '@material-ui/core/styles';

export const useLoginFormStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        '& label, & input': {
            color: theme.palette.common.black,
            lineHeight: '1',
            '&:-webkit-autofill': {
                '-webkit-box-shadow': 'initial',
                '-webkit-text-fill-color': 'initial',
            },
        },
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        padding: theme.spacing(1),
        background: theme.custom.accentMedium[1],
        '&:hover': {
            background: theme.custom.accentMedium[1],
        },
    },
    loginForm: {
        margin: theme.spacing(20, 4),
    },
}));

export const useInputStyles = makeStyles(theme => ({
    root: {
        '&:hover $notchedOutline': {
            borderColor: theme.custom.accentMedium[1],
        },
        '&$focused $notchedOutline': {
            borderColor: theme.custom.accentMedium[1],
        },
    },
    notchedOutline: {
        borderColor: theme.custom.accentMedium[1],
        color: 'red',
    },
    focused: {},
}));
