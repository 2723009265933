import React from 'react';
import { Field } from 'react-final-form';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';

const CheckboxWrapper = ({ input: { name, onChange, value }, ...rest }) => {
    // Need some custom logic here since mediabank handles values a strings "true" and "false"
    const handleChange = (_, value) => {
        onChange(value ? 'true' : 'false');
    };

    return <Checkbox checked={value === 'true' ? true : false} name={name} onChange={handleChange} {...rest} />;
};

CheckboxWrapper.propTypes = {
    input: PropTypes.object.isRequired,
};

const FormCheckboxField = props => <Field component={CheckboxWrapper} {...props} />;

export { CheckboxWrapper };

export default FormCheckboxField;
