import React from 'react';
import { Typography } from '@material-ui/core';
import { errorPayload } from '../utils/error';
import { AxiosError } from 'axios';

interface ErrorPageProps {
    statusCode?: number;
}

const ErrorPage = ({ statusCode = 404 }: ErrorPageProps) => {
    const error = errorPayload({ response: { status: statusCode } } as AxiosError);

    return (
        <Typography
            variant="h1"
            style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '200px',
            }}
            data-cy={`errorPage${statusCode}`}
        >
            {`${error.code}: ${error.message}`}
        </Typography>
    );
};

export default ErrorPage;
