import axios, { AxiosError } from 'axios';

export const errorPayload = (error: AxiosError) => {
    let errorCode;
    let errorMessage;
    let errorFeedback;

    if (error.response) {
        errorCode = error.response.status;

        switch (errorCode) {
            case 400:
                errorMessage = 'Error in server response';
                break;
            case 401:
                errorMessage = 'Unauthorized';
                break;
            case 403:
                errorMessage = 'Permission not allowed';
                break;
            case 404:
                errorMessage = 'API Route is Missing or Undefined';
                break;
            case 405:
                errorMessage = 'API Route Method Not Allowed';
                break;
            case 422:
                errorMessage = 'Unprocessable Entity';
                break;
            case 500:
                errorMessage = 'Server Error';
                break;
            default:
                errorMessage = 'Unspecified error';
                break;
        }
    } else if (error.request) {
        errorMessage = error.message;
        errorFeedback = 'Unable to send request.';
    } else {
        errorMessage = error.message;
    }

    return { code: errorCode, message: errorMessage, feedback: errorFeedback };
};

export const handleToastError = (
    error: unknown,
    title: string,
    caption: string,
    addToastError: Function,
    dataCy: string
) => {
    if (axios.isAxiosError(error)) {
        const errorResponse = errorPayload(error);
        addToastError({
            cy: dataCy,
            title: title,
            subtitle: `${errorResponse.code ? errorResponse.code + ': ' : ''}${errorResponse.message}`,
            caption: errorResponse.feedback ? errorResponse.feedback : caption,
        });
    }
};
