import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

const PublicRoute = ({ children, ...rest }: RouteProps) => (
    <Route
        {...rest}
        render={({ location }) =>
            !localStorage.getItem('mediabankToken') ? (
                children
            ) : (
                <Redirect
                    to={{
                        pathname: '/',
                        state: { from: location },
                    }}
                />
            )
        }
    />
);

export default PublicRoute;
