import { mediabankApi as api, mediabankHeaders } from '..';

const getConfig = async configKey => {
    const reqUrl = configKey ? `/config/customer/${configKey}` : '/config/customer';
    const response = await api.get(reqUrl, { headers: mediabankHeaders() });
    return response.data;
};

const getUserConfig = async () => {
    const response = await api.get('/config/user', { headers: mediabankHeaders() });
    return response.data;
};

const updateUserConfigMiniEnvSettings = async ({ userId, payload }) => {
    const response = await api.post(`/config/user/${userId}/updateMiniEnvironmentSettings`, {
        headers: mediabankHeaders(),
        data: {
            data: {
                type: 'miniEnvironmentSettings',
                attributes: {
                    minienvironmentsettings: payload,
                },
            },
        },
    });
    return response.data;
};

const getCompanyConfig = async () => {
    const response = await api.get('/config/company', { headers: mediabankHeaders() });
    return response.data;
};

const getCompanies = async () => {
    const response = await api.get('/config/allowed/companies', { headers: mediabankHeaders() });
    return response.data;
};

export const getBurnInLogos = async () => {
    const response = await api.get('/config/burninlogos', { headers: mediabankHeaders() });
    return response.data;
};

const getApps = async () => {
    const response = await api.get('/config/allowed/apps', { headers: mediabankHeaders() });
    return response.data;
};

const toggleTabState = async (namespace, id, value) => {
    const data = {
        type: 'ui/tabstatus',
        attributes: {
            open: value,
        },
    };
    const response = await api.put(`/config/user/toggletabstate/${namespace}/${id}`, {
        headers: mediabankHeaders(),
        data: { data },
    });

    return response;
};

export default {
    getConfig,
    getUserConfig,
    getCompanyConfig,
    getCompanies,
    getApps,
    toggleTabState,
    updateUserConfigMiniEnvSettings,
};
