import React, { useEffect, useState, useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { NotificationsContext } from '@mediabank/uikit';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import ErrorPage from '../pages/ErrorPage';
import LoginPage from '../pages/LoginPage';
import ProtectedRoutes from './ProtectedRoutes';
import { errorPayload } from '../utils/error';
import axios from 'axios';
import { useQueryClient } from 'react-query';
import { AuthProps } from '../lib/Auth';

// remember to update `rewrites` in vercel.json as routes are changed
const AppRoutes = ({ auth: tryAuthWithStoredToken }: Required<AuthProps>) => {
    const [authChecked, setAuthChecked] = useState(false);

    const { addToastError } = useContext(NotificationsContext);

    const queryClient = useQueryClient();

    useEffect(() => {
        if (!authChecked) {
            tryAuthWithStoredToken()
                .then(userProfile => {
                    const globalQueryDefaultOptions = queryClient.getDefaultOptions();
                    queryClient.setDefaultOptions({
                        ...globalQueryDefaultOptions,
                        queries: { ...globalQueryDefaultOptions.queries, cacheTime: Infinity },
                    });
                    queryClient.setQueryData('userProfile', userProfile);
                    queryClient.setDefaultOptions(globalQueryDefaultOptions);
                })
                .catch(error => {
                    if (axios.isAxiosError(error)) {
                        const errorResponse = errorPayload(error);
                        addToastError({
                            title: 'Auth fail',
                            subtitle: `${errorResponse.code ? errorResponse.code + ': ' : ''}${errorResponse.message}`,
                            cy: 'unauthorizedError',
                            caption:
                                errorResponse.code === 401
                                    ? 'The authentication session has expired. Please log in.'
                                    : 'Something went wrong. Please log in.',
                        });
                    }
                })
                .finally(() => setAuthChecked(true));
        }
    }, [addToastError, authChecked, queryClient, tryAuthWithStoredToken]);

    return (
        <>
            {authChecked && (
                <Switch>
                    <PublicRoute exact path="/login">
                        <LoginPage />
                    </PublicRoute>
                    <PrivateRoute path="/">
                        <ProtectedRoutes />
                    </PrivateRoute>

                    <Route path="*" component={ErrorPage} />
                </Switch>
            )}
        </>
    );
};

export default AppRoutes;
