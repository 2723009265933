import React, { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { Roles } from '../constants/roles';
import { UserProfile } from '../lib/UserProfile';
import ErrorPage from '../pages/ErrorPage';

const PrivateRoute = ({ children, ...rest }: RouteProps) => {
    const queryClient = useQueryClient();
    const userProfile = queryClient.getQueryData<UserProfile>('userProfile');
    const userHasRequiredRole = useMemo(
        () =>
            userProfile &&
            [Roles.ROLE_COLLECTION_ADMIN, Roles.ROLE_COLLECTION_CREATE_EDIT_DELETE].some(
                role => userProfile.roles.indexOf(role) !== -1
            ),
        [userProfile]
    );
    return (
        <Route
            {...rest}
            render={({ location }) =>
                localStorage.getItem('mediabankToken') ? (
                    userHasRequiredRole ? (
                        children
                    ) : (
                        <ErrorPage statusCode={403} />
                    )
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;
