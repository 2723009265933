import './web-components/publicPath';

import React from 'react';
import ReactDOM from 'react-dom';

import LocalAdminApp from './web-components/LocalAdminApp';

const defineCustomReactElement = (tag: string, ReactElement: () => JSX.Element) =>
    customElements.define(
        tag,
        class extends HTMLElement {
            connectedCallback() {
                ReactDOM.render(<ReactElement />, this);
            }
        }
    );

defineCustomReactElement('obx-local-admin', LocalAdminApp);
