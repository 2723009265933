import { mediabankApi as api, mediabankHeaders } from '../../mediabank';

const create = async ({ attributes, companyId }) => {
    const createRequest = { data: { type: 'groups', attributes: attributes } };
    return await api.post(`/admin/companies/${companyId}/groups`, { data: createRequest, headers: mediabankHeaders() });
};

const getAll = async ({ companyId }) =>
    await api.get(`/admin/companies/${companyId}/groups`, { headers: mediabankHeaders() });

const getOne = async ({ companyId, groupId }) =>
    await api.get(`/admin/companies/${companyId}/groups/${groupId}`, { headers: mediabankHeaders() });

export default { getAll, getOne, create };
