const YYYYMMDDToDate = ({ yyyymmdd }) => {
    if (!yyyymmdd) {
        return;
    }
    const [year, month, day] = yyyymmdd.split('-');

    return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
};

export { YYYYMMDDToDate };
