import React from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LoginForm from '../components/LoginForm';
import blurry from '../assets/blurry.jpg';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        background: theme.palette.common.white,
    },
    video: {
        overflow: 'hidden',
        '& video': {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
        },
    },
}));

const LoginPage = () => {
    const classes = useStyles();

    return (
        <Grid container component="main" className={classes.root}>
            <Grid item xs={12} sm={8} md={4}>
                <LoginForm />
            </Grid>
            <Hidden xsDown>
                <Grid item sm={4} md={8} className={classes.video}>
                    <img alt="blurry" src={blurry} />
                </Grid>
            </Hidden>
        </Grid>
    );
};

export default LoginPage;
